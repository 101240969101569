.hero__benefit {
  align-self: flex-start;
  background-image: url("../../../images/icons/check-mark.svg");
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 59px;
  font-size: 32px;
  font-weight: 400;
  line-height: 39.01px;
  text-align: left;
  margin-bottom: 22px;
}

@media screen and (max-width: 768px) {
  .hero__benefit {
    background-size: 12px 9px;
    font-size: 20px;
    line-height: 24.38px;
    padding-left: 21px;
    margin-bottom: 8px;
  }
}
