.hero__quote-author {
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  line-height: 29.26px;
  text-align: left;
  color: #858080;
  margin: 0;
  margin-top: 32px;
}

@media screen and (max-width: 768px) {
  .hero__quote-author {
    font-size: 14px;
    line-height: 17.07px;
    max-width: 130px;
    margin-top: 11px;
  }
}