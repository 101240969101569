.course {
  display: flex;
  flex-direction: column;
  max-width: 786px;
  width: 100%;
  height: 545px;
  border-radius: 32.85px;
  background-color: white;
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .course {
    height: 500px;
  }
}

@media screen and (max-width: 768px) {
  .course {
    max-width: 341px;
    height: 316px;
    border-radius: 12px;
    margin-top: 29px;
  }
}
