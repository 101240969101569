.header__button {
  font-family: "Roboto_Condensed";
  font-size: 28px;
  font-weight: 400;
  line-height: 32.81px;
  background: rgba(26, 26, 26, 1);
  opacity: 80%;
  color: #fff;
  width: 317px;
  height: 80px;
  border: none;
  border-radius: 164px;
  padding: 10px 20px;
  margin-right: 10px;
  margin-left: 15px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.header__button:hover {
  opacity: 60%;
}

@media screen and (max-width: 768px) {
  .header__button {
    width: 116px;
    height: 34px;
    top: 13px;
    left: 242px;
    padding: 10px 0;
    gap: 10px;
    border-radius: 60px;
    font-size: 12px;
    line-height: 14.06px;
  }
}
