.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;

}

@media screen and (max-width: 768px) {
  .main {
    max-width: 355px;
  }
}