.footer__text {
  font-family: "Roboto_Condensed";
  font-size: 32px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: center;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .footer__text {
    font-size: 14px;
    line-height: 16.41px;
  }
}
