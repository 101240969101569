.hero__quote {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: self-end;
  max-width: 500px;
  margin: 0;
  margin-top: 131px;
}

.hero__quote::before {
  content: "";
  background-image: url("../../../images/icons/comma.svg");
  background-repeat: no-repeat;
  background-position: left center;
  width: 85px;
  height: 78px;
  position: absolute;
  top: -31px;
  left: -114px;
  color: #535353;
}

@media screen and (max-width: 450px) {
  .hero__quote {
    max-width: 295px;
  }

  .hero__quote::before {
    background-size: 55px;
    top: -31px;
    left: -67px;
  }
}

@media screen and (max-width: 768px) {
  .hero__quote {
    margin-top: 52px;
    max-width: 261px;
  }

  .hero__quote::before {
    background-size: 52px;
    top: -25px;
    left: -64px;
  }
}
