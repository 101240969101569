.author__photo {
  width: 260px;
  height: 260px;
  margin-right: 34px;
  margin-top: 36px;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .author__photo {
    width: 88px;
    height: 88px;
    margin-top: 12px;
    margin-right: 16px;
  }
}
