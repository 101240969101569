@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url("Montserrat-Regular.5c4ab150.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url("Montserrat-Bold.1b1f71c4.ttf") format("truetype");
}

@font-face {
  font-family: Roboto_Condensed;
  font-style: normal;
  font-weight: 400;
  src: url("RobotoCondensed-Regular.3b329398.ttf") format("truetype");
}

.body {
  margin: 0;
  padding: 0;
}

.page {
  color: #1a1a1a;
  background: linear-gradient(#eecfb6 0%, #e2dcbb 100%);
  height: 100%;
  margin: 0 auto;
  font-family: Montserrat;
}

.header {
  justify-content: space-between;
  width: 100%;
  max-width: 946px;
  margin: 0 auto;
  padding-top: 50px;
  display: flex;
}

@media screen and (width <= 768px) {
  .header {
    max-width: 360px;
    padding-top: 13px;
  }
}

.header__button {
  opacity: .8;
  color: #fff;
  cursor: pointer;
  background: #1a1a1a;
  border: none;
  border-radius: 164px;
  width: 317px;
  height: 80px;
  margin-left: 15px;
  margin-right: 10px;
  padding: 10px 20px;
  font-family: Roboto_Condensed;
  font-size: 28px;
  font-weight: 400;
  line-height: 32.81px;
  transition: opacity .2s ease-in-out;
}

.header__button:hover {
  opacity: .6;
}

@media screen and (width <= 768px) {
  .header__button {
    border-radius: 60px;
    gap: 10px;
    width: 116px;
    height: 34px;
    padding: 10px 0;
    font-size: 12px;
    line-height: 14.06px;
    top: 13px;
    left: 242px;
  }
}

.header__logo {
  text-align: left;
  max-width: 200px;
  margin-left: 10px;
  font-family: Roboto_Condensed;
  font-size: 44.31px;
  font-weight: 400;
  line-height: 35.45px;
}

@media screen and (width <= 768px) {
  .header__logo {
    max-width: 100px;
    margin-top: 4px;
    font-size: 16px;
    line-height: 12.8px;
  }
}

.main {
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
}

@media screen and (width <= 768px) {
  .main {
    max-width: 355px;
  }
}

.hero {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.hero__benefit {
  text-align: left;
  background-image: url("check-mark.d7efb763.svg");
  background-position: 0;
  background-repeat: no-repeat;
  align-self: flex-start;
  margin-bottom: 22px;
  padding-left: 59px;
  font-size: 32px;
  font-weight: 400;
  line-height: 39.01px;
}

@media screen and (width <= 768px) {
  .hero__benefit {
    background-size: 12px 9px;
    margin-bottom: 8px;
    padding-left: 21px;
    font-size: 20px;
    line-height: 24.38px;
  }
}

.hero__benefits {
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  max-width: 500px;
  margin-top: 39px;
  margin-bottom: 20px;
  margin-left: 17px;
  padding: 0;
  list-style: none;
  display: flex;
}

@media screen and (width <= 768px) {
  .hero__benefits {
    margin-top: 30px;
    margin-left: 7px;
  }
}

.hero__form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 786px;
  margin-top: 34px;
  display: flex;
}

@media screen and (width <= 768px) {
  .hero__form {
    max-width: 341px;
    margin: 0;
  }
}

.hero__input {
  text-align: center;
  color: #1a1a1a;
  border: none;
  border-radius: 32.85px;
  outline: none;
  width: 100%;
  height: 112px;
  font-family: Roboto_Condensed;
  font-size: 40px;
  font-weight: 400;
  line-height: 46.88px;
}

.hero__input::placeholder {
  color: #858080b3;
}

.hero__input:focus::placeholder {
  color: #0000;
}

@media screen and (width <= 768px) {
  .hero__input {
    border-radius: 12px;
    width: 98%;
    height: 60px;
    font-size: 20px;
    line-height: 23.44px;
  }
}

.hero__input-highlighted {
  border: 2px solid #f25252;
}

.hero__quote {
  flex-direction: column;
  align-self: self-end;
  max-width: 500px;
  margin: 131px 0 0;
  display: flex;
  position: relative;
}

.hero__quote:before {
  content: "";
  color: #535353;
  background-image: url("comma.79aba920.svg");
  background-position: 0;
  background-repeat: no-repeat;
  width: 85px;
  height: 78px;
  position: absolute;
  top: -31px;
  left: -114px;
}

@media screen and (width <= 450px) {
  .hero__quote {
    max-width: 295px;
  }

  .hero__quote:before {
    background-size: 55px;
    top: -31px;
    left: -67px;
  }
}

@media screen and (width <= 768px) {
  .hero__quote {
    max-width: 261px;
    margin-top: 52px;
  }

  .hero__quote:before {
    background-size: 52px;
    top: -25px;
    left: -64px;
  }
}

.hero__quote-author {
  text-align: left;
  color: #858080;
  margin: 32px 0 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.26px;
}

@media screen and (width <= 768px) {
  .hero__quote-author {
    max-width: 130px;
    margin-top: 11px;
    font-size: 14px;
    line-height: 17.07px;
  }
}

.hero__quote-text {
  text-align: left;
  width: 100%;
  max-width: 470px;
  margin: 0;
  font-size: 32px;
  font-weight: 400;
  line-height: 39.01px;
}

@media screen and (width <= 768px) {
  .hero__quote-text {
    font-size: 16px;
    line-height: 19.5px;
  }
}

.hero__submit {
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(85.09deg, #1a1a1a 32.97%, gray 109.24%);
  border: none;
  border-radius: 164.24px;
  width: 100%;
  height: 112px;
  margin-top: 29px;
  padding: 10px 20px;
  font-family: Roboto_Condensed;
  font-size: 40px;
  font-weight: 400;
  line-height: 46.88px;
  transition: opacity .2s ease-in-out;
}

.hero__submit:hover {
  opacity: .8;
}

@media screen and (width <= 768px) {
  .hero__submit {
    border-radius: 60px;
    height: 60px;
    margin-top: 11px;
    font-size: 20px;
    line-height: 23.44px;
  }
}

.hero__title {
  text-align: left;
  color: #858080;
  max-width: 795px;
  margin: 74px 0 0;
  font-size: 67.49px;
  font-weight: 700;
  line-height: 82.27px;
}

@media screen and (width <= 768px) {
  .hero__title {
    max-width: 344px;
    margin-top: 40px;
    font-size: 29.2px;
    line-height: 35.59px;
  }
}

.hero__title-transform {
  color: #1a1a1a;
}

.course {
  background-color: #fff;
  border-radius: 32.85px;
  flex-direction: column;
  width: 100%;
  max-width: 786px;
  height: 545px;
  margin-top: 100px;
  display: flex;
}

@media screen and (width <= 768px) {
  .course {
    border-radius: 12px;
    max-width: 341px;
    height: 316px;
    margin-top: 29px;
  }
}

.course__item {
  text-align: left;
  background-image: url("check-mark.d7efb763.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 20px 15px;
  margin-bottom: 13px;
  padding-left: 35px;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.26px;
}

@media screen and (width <= 768px) {
  .course__item {
    background-size: 12px 9px;
    max-width: 235px;
    margin-bottom: 9px;
    padding-left: 21px;
    font-size: 16px;
    line-height: 19.5px;
  }
}

.course__list {
  margin: 26px 0 0 60px;
  padding: 0;
  list-style: none;
}

@media screen and (width <= 768px) {
  .course__list {
    margin-top: 16px;
    margin-left: 24px;
  }
}

.course__title {
  text-align: left;
  max-width: 450px;
  margin: 60px 0 0 60px;
  font-size: 48px;
  font-weight: 700;
  line-height: 58.51px;
}

@media screen and (width <= 768px) {
  .course__title {
    max-width: 175px;
    margin-top: 25px;
    margin-left: 25px;
    font-size: 18px;
    line-height: 21.94px;
  }
}

.course__title-transform {
  color: #858080;
}

.author {
  width: 100%;
  max-width: 783px;
  margin-top: 101px;
}

@media screen and (width <= 768px) {
  .author {
    max-width: 342px;
    margin-top: 44px;
  }
}

.author__identity {
  align-items: center;
  display: flex;
}

.author__description {
  max-width: 600px;
  margin-top: 36px;
}

@media screen and (width <= 768px) {
  .author__description {
    margin-top: 12px;
  }
}

.author__detail {
  text-align: left;
  background-image: url("Subtract.0a03946e.svg");
  background-position: 0;
  background-repeat: no-repeat;
  max-width: 553px;
  margin-bottom: 18px;
  padding-left: 40px;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.26px;
}

@media screen and (width <= 768px) {
  .author__detail {
    background-size: 9px;
    max-width: 315px;
    margin-bottom: 8px;
    padding-left: 19px;
    font-size: 14px;
    line-height: 17.07px;
  }
}

.author__detail-link {
  color: #1a1a1a;
}

.author__details {
  margin: 0;
  padding: 0;
  list-style: none;
}

.author__info {
  flex-direction: column;
  display: flex;
}

.author__name {
  text-align: left;
  max-width: 285px;
  margin: 36px 0 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 58.51px;
}

@media screen and (width <= 768px) {
  .author__name {
    max-width: 110px;
    margin-top: 12px;
    font-size: 18px;
    line-height: 21.94px;
  }
}

.author__photo {
  border-radius: 50%;
  width: 260px;
  height: 260px;
  margin-top: 36px;
  margin-right: 34px;
}

@media screen and (width <= 768px) {
  .author__photo {
    width: 88px;
    height: 88px;
    margin-top: 12px;
    margin-right: 16px;
  }
}

.author__title {
  text-align: left;
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 58.51px;
}

@media screen and (width <= 768px) {
  .author__title {
    font-size: 18px;
    line-height: 21.94px;
  }
}

.footer {
  text-align: center;
  color: #fff;
  background-color: #1a1a1a;
  border-radius: 32.85px 32.85px 0 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 131px;
  margin: 101px auto 0;
  display: flex;
}

@media screen and (width <= 768px) {
  .footer {
    border-radius: 12px 12px 0 0;
    height: 60px;
    margin-top: 50px;
  }
}

.footer__text {
  text-align: center;
  margin: 0;
  font-family: Roboto_Condensed;
  font-size: 32px;
  font-weight: 400;
  line-height: 37.5px;
}

@media screen and (width <= 768px) {
  .footer__text {
    font-size: 14px;
    line-height: 16.41px;
  }
}
/*# sourceMappingURL=index.f19e94d3.css.map */
