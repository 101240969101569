.author {
  margin-top: 101px;
  max-width: 783px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .author {
    max-width: 342px;
    margin-top: 44px;
  }
}
