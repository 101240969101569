.author__name {
  font-size: 48px;
  font-weight: 700;
  line-height: 58.51px;
  text-align: left;
  margin: 0;
  margin-top: 36px;
  max-width: 285px;
}

@media screen and (max-width: 768px) {
  .author__name {
    font-size: 18px;
    line-height: 21.94px;
    max-width: 110px;
    margin-top: 12px;
  }
}
