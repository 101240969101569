.footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 131px;
  text-align: center;
  background-color: #1a1a1a;
  color: #fff;
  border-radius: 32.85px 32.85px 0 0;
  margin: 0 auto;
  margin-top: 101px;
}

@media screen and (max-width: 768px) {
  .footer {
    height: 60px;
    margin-top: 50px;
    border-radius: 12px 12px 0 0;
  }
}