.hero__benefits {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  list-style: none;
  max-width: 500px;
  padding: 0;
  margin-bottom: 20px;
  margin-top: 39px;
  margin-left: 17px;
}

@media screen and (max-width: 768px) {
  .hero__benefits {
    margin-top: 30px;
    margin-left: 7px;
  }
}