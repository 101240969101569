.author__title {
  font-size: 48px;
  font-weight: 700;
  line-height: 58.51px;
  text-align: left;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .author__title {
    font-size: 18px;
    line-height: 21.94px;
  }
}
