.hero__quote-text {
  font-size: 32px;
  font-weight: 400;
  line-height: 39.01px;
  text-align: left;
  max-width: 470px;
  width: 100%;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .hero__quote-text {
    font-size: 16px;
    line-height: 19.5px;
  }
}
