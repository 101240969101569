.hero__input {
  font-family: "Roboto_Condensed";
  font-size: 40px;
  font-weight: 400;
  line-height: 46.88px;
  text-align: center;
  width: 100%;
  height: 112px;
  border: none;
  outline: none;
  color: rgba(26, 26, 26, 1);
  border-radius: 32.85px;
}

.hero__input::placeholder {
  color: rgba(133, 128, 128, 0.7);
}

.hero__input:focus::placeholder {
  color: transparent;
}

@media screen and (max-width: 768px) {
  .hero__input {
    width: 98%;
    height: 101px;
  }
}

@media screen and (max-width: 768px) {
  .hero__input {
    border-radius: 12px;
    font-size: 20px;
    line-height: 23.44px;
    height: 60px;
  }
}
