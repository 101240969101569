.author__detail {
  background-image: url("../../../images/icons/Subtract.svg");
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 40px;
  margin-bottom: 18px;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.26px;
  text-align: left;
  max-width: 553px;
}

@media screen and (max-width: 768px) {
  .author__detail {
    font-size: 14px;
    line-height: 17.07px;
    background-size: 9px;
    padding-left: 19px;
    max-width: 315px;
    margin-bottom: 8px;
  }
}
