.course__list {
  padding: 0;
  margin: 0;
  margin-left: 60px;
  margin-top: 26px;
  list-style: none;
}

@media screen and (max-width: 768px) {
  .course__list {
    margin-left: 24px;
    margin-top: 16px;
  }
}