.course__title {
  font-size: 48px;
  font-weight: 700;
  line-height: 58.51px;
  text-align: left;
  margin: 0;
  margin-top: 60px;
  margin-left: 60px;
  max-width: 450px;
}

@media screen and (max-width: 768px) {
  .course__title {
    margin-top: 35px;
    font-size: 6vw;
    line-height: 7vw;
  }
}

@media screen and (max-width: 768px) {
  .course__title {
    font-size: 18px;
    line-height: 21.94px;
    max-width: 175px;
    margin-left: 25px;
    margin-top: 25px;
  }
}
