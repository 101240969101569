.header {
  display: flex;
  width: 100%;
  max-width: 946px;
  margin: 0 auto;
  padding-top: 50px;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .header {
    padding-top: 13px;
    max-width: 360px;
  }
}
