.hero__title {
  font-size: 67.49px;
  font-weight: 700;
  line-height: 82.27px;
  text-align: left;
  color: #858080;
  margin: 0;
  margin-top: 74px;
  max-width: 795px;
}

@media screen and (max-width: 768px) {
  .hero__title {
    font-size: 8.2vw;
    line-height: 11.2vw;
    max-width: 726px;
  }
}

@media screen and (max-width: 768px) {
  .hero__title {
    margin-top: 40px;
    max-width: 344px;
    font-size: 29.2px;
    line-height: 35.59px;
  }
}
