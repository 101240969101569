.course__item {
  background-image: url("../../../images/icons/check-mark.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px 15px;
  padding-left: 35px;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.26px;
  text-align: left;
  margin-bottom: 13px;
}

@media screen and (max-width: 768px) {
  .course__item {
    background-size: 12px 9px;
    font-size: 16px;
    line-height: 19.5px;
    padding-left: 21px;
    margin-bottom: 9px;
    max-width: 235px;
  }
}
