.author__description {
  max-width: 600px;
  margin-top: 36px;
}

@media screen and (max-width: 768px) {
  .author__description {
    margin-top: 12px;
  }
}
