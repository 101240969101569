.header__logo {
  font-family: "Roboto_Condensed";
  font-size: 44.31px;
  font-weight: 400;
  line-height: 35.45px;
  text-align: left;
  margin-left: 10px;
  max-width: 200px;
}

@media screen and (max-width: 768px) {
  .header__logo {
    margin-top: 4px;
    max-width: 100px;
    font-size: 16px;
    line-height: 12.8px;
  }
}
