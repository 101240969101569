.hero__submit {
  font-family: "Roboto_Condensed";
  font-size: 40px;
  font-weight: 400;
  line-height: 46.88px;
  text-align: center;
  width: 100%;
  height: 112px;
  padding: 10px 20px;
  margin-top: 29px;
  background: linear-gradient(85.09deg, #1a1a1a 32.97%, #808080 109.24%);
  color: #fff;
  border: none;
  border-radius: 164.24px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.hero__submit:hover {
  opacity: 80%;
}

@media screen and (max-width: 768px) {
  .hero__submit {
    height: 101px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .hero__submit {
    font-size: 20px;
    line-height: 23.44px;
    border-radius: 60px;
    height: 60px;
    margin-top: 11px;
  }
}
